<template>
  <div>
    <div
      v-if="selectlan == 'EN'"
      class="container-fluid show-mobile fixed-nav"
      style="opacity: 1"
    >
      <div class="d-flex py-7">
        <div style="flex-grow: 1; padding: 0% 1% 0% 4%">
          <a class="navbar-brand" href="/" style="margin-top: -6px">
            <svg
              width="140"
              style="margin-top: -6px"
              height="35"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div class="mr-4 pt-1" v-if="!menuOpen">
          <a class="lang" @click="setLang('EN')" v-if="selectlan == 'ARABIC'"
            >EN</a
          >
          <a class="lang" @click="setLang('ARABIC')" v-if="selectlan == 'EN'"
            >عربي</a
          >
        </div>
        <div v-if="menuOpen" class="mr-4 pt-1">
          <a
            class="header-contact2"
            style="line-height: 0px; text-decoration: none"
          >
            <v-icon color="primary" style="margin-top: -2px">
              mdi-phone-in-talk
            </v-icon>
            1882707</a
          >
        </div>
        <div class="hamburger-wrap">
          <!-- <button
            class="hamburger-menu"
            v-if="!menuOpen"
            @click="menuOpen = !menuOpen"
          >
            &#9776;
          </button> -->
          <div id="nav-icon4">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- <button
            class="hamburger-menu"
            v-if="menuOpen"
            @click="menuOpen = !menuOpen"
          >
            <v-icon>
              mdi-close-circle-outline
            </v-icon>
          </button> -->
        </div>
      </div>
      <div class="dropdownmobile" :class="{ 'dropdownmobile-after': menuOpen }">
        <div class="mobile-nav">
          <div class="mobile-nav-div" style="margin-top: 0px" v-if="log_token">
            <div class="d-flex pointer" @click="myprofile = !myprofile">
              <div
                class="mobile-sub-link mobile-sub-header h-100 ma"
                style="color: #212832; font-size: 20px"
                v-if="$store.state.customer != null"
              >
                {{ $store.state.customer.name }}
              </div>
              <div>
                <img
                  class="mr-3"
                  :class="{ 'fa-arrow-down-open': myprofile }"
                  src="../assets/ar.png"
                  alt=""
                />
              </div>
            </div>
            <transition-expand>
              <div v-if="myprofile">
                <ul class="drop-list" style="margin-bottom: 0px">
                  <li>
                    <a
                      :href="
                        $router.resolve({
                          path: '/cart',
                        }).href
                      "
                    >
                      Cart</a
                    >
                  </li>
                  <li>
                    <a
                      :href="
                        $router.resolve({
                          path: '/my/profile',
                        }).href
                      "
                    >
                      My Profile</a
                    >
                  </li>
                  <li>
                    <a
                      :href="
                        $router.resolve({
                          path: '/my/evaluation',
                        }).href
                      "
                    >
                      My Evaluation</a
                    >
                  </li>
                  <li>
                    <a @click="logout()"> Logout</a>
                  </li>
                </ul>
              </div>
            </transition-expand>
          </div>
          <div class="mobile-nav-link" v-else @click="loginWin()">Sign In</div>
          <div class="mobile-nav-link mobile-nav-div mt-10">
            SERVICES
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(dc = !dc), (sp = false), (kt = false), (ic = false)"
              >
                <div class="mobile-sub-link mobile-sub-header h-100 ma">
                  DETAILED CLEANING
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': dc }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="dc">
                  <ul class="drop-list">
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/generalcleaning',
                          }).href
                        "
                        >General Cleaning
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/deepcleaning',
                          }).href
                        "
                      >
                        Deep Cleaning
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/storagearea',
                          }).href
                        "
                      >
                        Storage Area</a
                      >
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/windowcleaning',
                          }).href
                        "
                      >
                        Window Cleaning</a
                      >
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/outdoorcleaning',
                          }).href
                        "
                      >
                        Outdoor Cleaning
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/carparking',
                          }).href
                        "
                      >
                        Car Parking Umbrella
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/facadecleaning',
                          }).href
                        "
                      >
                        Facade Cleaning
                      </a>
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(sp = !sp), (dc = false), (kt = false), (ic = false)"
              >
                <div class="mobile-sub-link mobile-sub-header h-100 ma">
                  SPECIAL CARE
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': sp }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="sp">
                  <ul class="drop-list">
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/specialcare/upholsterycleaning',
                          }).href
                        "
                      >
                        Upholstery Cleaning
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/specialcare/carpetcleaning',
                          }).href
                        "
                      >
                        Carpet Cleaning
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/specialcare/mattresscleaning',
                          }).href
                        "
                        >Mattress Cleaning
                      </a>
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(kt = !kt), (sp = false), (dc = false), (ic = false)"
              >
                <div class="mobile-sub-link mobile-sub-header h-100 ma">
                  KITCHEN CLEANING
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': kt }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="kt">
                  <ul class="drop-list">
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/kitchencleaning/kitchencleaning',
                          }).href
                        "
                      >
                        Kitchen Cleaning</a
                      >
                    </li>
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/kitchencleaning/kitchenappliances',
                          }).href
                        "
                      >
                        Kitchen Appliances</a
                      >
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(ic = !ic), (sp = false), (kt = false), (dc = false)"
              >
                <div class="mobile-sub-link mobile-sub-header h-100 ma">
                  INFECTION CONTROL
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': ic }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="ic">
                  <ul class="drop-list">
                    <li>
                      <a
                        :href="
                          $router.resolve({
                            path: '/service/infectioncontrol/sanitisation&disinfection',
                          }).href
                        "
                      >
                        Sanitization & Disinfection</a
                      >
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
          </div>

          <br />
          <div class="mobile-nav-link" @click="gotoSpeciality()">
            Speciality
          </div>
          <div class="mobile-nav-link" @click="goto('teams')">Team</div>
          <div class="mobile-nav-link" @click="goto('blog')">BLOG</div>

          <!-- <div class="mobile-sub">
              <div class="d-flex pointer" @click="dc = !dc, sp = false, kt=false, ic = false">
                 <div class="">
                 DETAILED CLEANING
               </div>
               </div>
          </div> -->
        </div>
        <div class="mobile-navsocial py-5" v-if="menuOpen">
          <div class="text-center">
            <img
              onclick="window.open('https://www.facebook.com/BleachKw/', '_blank')"
              src="../assets/icons/mobile/fb.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://www.instagram.com/bleachkw/', '_blank')"
              src="../assets/icons/mobile/is.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://www.linkedin.com/company/bleachkw', '_blank')"
              src="../assets/icons/mobile/in.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://twitter.com/bleachkw?lang=en', '_blank')"
              src="../assets/icons/mobile/tw.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://www.youtube.com/channel/UC_XhgxfirQrft_kAFRZPAOw', '_blank')"
              src="../assets/icons/mobile/you.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div
      dir="rtl"
      v-if="selectlan == 'ARABIC'"
      class="container-fluid show-mobile fixed-nav"
      style="opacity: 1"
    >
      <div class="d-flex py-7">
        <div style="flex-grow: 1; padding: 0% 1% 0% 4%">
          <a class="navbar-brand" href="/" style="margin-top: -6px">
            <svg
              width="140"
              style="margin-top: -6px"
              height="35"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div class="mr-4 pt-1" v-if="!menuOpen">
          <a class="lang" @click="setLang('EN')" v-if="selectlan == 'ARABIC'"
            >EN</a
          >
          <a class="lang" @click="setLang('ARABIC')" v-if="selectlan == 'EN'"
            >AR</a
          >
        </div>
        <div v-if="menuOpen" class="mr-4 pt-1">
          <a
            class="header-contact2"
            style="line-height: 0px; text-decoration: none"
          >
            <v-icon color="primary" style="margin-top: -2px">
              mdi-phone-in-talk
            </v-icon>
            1882707</a
          >
        </div>
        <div class="hamburger-wrap">
          <div id="nav-icon4">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- <button
            class="hamburger-menu"
            v-if="!menuOpen"
            @click="menuOpen = !menuOpen"
          >
            &#9776;
          </button>
          <button
            class="hamburger-menu"
            v-if="menuOpen"
            @click="menuOpen = !menuOpen"
          >
            <img src="../assets/close.png" style="width: 15px" alt="" />
          </button> -->
        </div>
      </div>
      <div class="dropdownmobile" :class="{ 'dropdownmobile-after': menuOpen }">
        <div class="mobile-nav">
          <div class="mobile-nav-div" style="margin-top: 0px" v-if="log_token">
            <div class="d-flex pointer" @click="myprofile = !myprofile">
              <div
                class="mobile-sub-link mobile-sub-header h-100 ma"
                style="color: #212832; font-size: 20px"
                v-if="$store.state.customer != null"
              >
                {{ $store.state.customer.name }}
              </div>
              <div>
                <img
                  class="mr-3"
                  :class="{ 'fa-arrow-down-open': myprofile }"
                  src="../assets/ar.png"
                  alt=""
                />
              </div>
            </div>
            <transition-expand>
              <div v-if="myprofile">
                <ul class="drop-list" style="margin-bottom: 0px">
                  <li>
                    <a
                      :href="
                        $router.resolve({
                          path: '/cart',
                        }).href
                      "
                    >
                      Cart</a
                    >
                  </li>
                  <li>
                    <a
                      :href="
                        $router.resolve({
                          path: '/my/profile',
                        }).href
                      "
                    >
                      My Profile</a
                    >
                  </li>
                  <li>
                    <a
                      :href="
                        $router.resolve({
                          path: '/my/evaluation',
                        }).href
                      "
                    >
                      My Evaluation</a
                    >
                  </li>
                  <li>
                    <a @click="logout()"> Logout</a>
                  </li>
                </ul>
              </div>
            </transition-expand>
          </div>
          <div class="mobile-nav-link" v-else @click="loginWin()">Sign In</div>
          <div class="mobile-nav-link mobile-nav-div arabic-font mt-10">
            خدمات
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(dc = !dc), (sp = false), (kt = false), (ic = false)"
              >
                <div
                  class="mobile-sub-link mobile-sub-header arabic-font h-100 ma"
                >
                  التنظيف الدقيق
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': dc }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="dc">
                  <ul class="drop-list">
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/generalcleaning',
                          }).href
                        "
                        >التنظيف العام
                      </a>
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/deepcleaning',
                          }).href
                        "
                      >
                        التنظيف العميق
                      </a>
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/storagearea',
                          }).href
                        "
                      >
                        تنظيف منطقة التخزين</a
                      >
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/windowcleaning',
                          }).href
                        "
                      >
                        تنظيف النوافذ</a
                      >
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/outdoorcleaning',
                          }).href
                        "
                      >
                        تنظيف المساحة الخارجية
                      </a>
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/carparking',
                          }).href
                        "
                      >
                        صيانة مظالت مواقف السيارات
                      </a>
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/detailedcleaning/facadecleaning',
                          }).href
                        "
                      >
                        تنظيف الواجهات
                      </a>
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(sp = !sp), (dc = false), (kt = false), (ic = false)"
              >
                <div
                  class="mobile-sub-link mobile-sub-header h-100 ma arabic-font"
                >
                  الرعاية الخاصة
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': sp }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="sp">
                  <ul class="drop-list">
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/specialcare/upholsterycleaning',
                          }).href
                        "
                      >
                        تنظيف المفروشات
                      </a>
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/specialcare/carpetcleaning',
                          }).href
                        "
                      >
                        تنظيف السجاد
                      </a>
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/specialcare/mattresscleaning',
                          }).href
                        "
                        >تنظيف المراتب
                      </a>
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(kt = !kt), (sp = false), (dc = false), (ic = false)"
              >
                <div
                  class="mobile-sub-link mobile-sub-header h-100 ma arabic-font"
                >
                  تنظيف المطبخ
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': kt }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="kt">
                  <ul class="drop-list">
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/kitchencleaning/kitchencleaning',
                          }).href
                        "
                      >
                        تنظيف المطبخ</a
                      >
                    </li>
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/kitchencleaning/kitchenappliances',
                          }).href
                        "
                      >
                        أجهزة المطبخ</a
                      >
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
            <div class="mobile-sub">
              <div
                class="d-flex pointer"
                @click="(ic = !ic), (sp = false), (kt = false), (dc = false)"
              >
                <div
                  class="mobile-sub-link mobile-sub-header h-100 ma arabic-font"
                >
                  مكافحة انتشار األمراض
                </div>
                <div>
                  <img
                    class="mr-3"
                    :class="{ 'fa-arrow-down-open': ic }"
                    src="../assets/ar.png"
                    alt=""
                  />
                </div>
              </div>
              <transition-expand>
                <div v-if="ic">
                  <ul class="drop-list">
                    <li>
                      <a
                        class="arabic-font"
                        :href="
                          $router.resolve({
                            path: '/service/infectioncontrol/sanitisation&disinfection',
                          }).href
                        "
                      >
                        التعقيم والتطهير</a
                      >
                    </li>
                  </ul>
                </div>
              </transition-expand>
            </div>
          </div>

          <br />
          <div class="mobile-nav-link arabic-font" @click="gotoSpeciality()">
            اختصاصاتنا
          </div>
          <div class="mobile-nav-link arabic-font" @click="goto('teams')">
            فريقنا
          </div>
          <div class="mobile-nav-link arabic-font" @click="goto('blog')">
            المدونة
          </div>
        </div>
        <div class="mobile-navsocial py-5" v-if="menuOpen">
          <div class="text-center">
            <img
              onclick="window.open('https://www.facebook.com/BleachKw/', '_blank')"
              src="../assets/icons/mobile/fb.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://www.instagram.com/bleachkw/', '_blank')"
              src="../assets/icons/mobile/is.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://www.linkedin.com/company/bleachkw', '_blank')"
              src="../assets/icons/mobile/in.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://twitter.com/bleachkw?lang=en', '_blank')"
              src="../assets/icons/mobile/tw.png"
              alt=""
            />
          </div>
          <div class="text-center">
            <img
              onclick="window.open('https://www.youtube.com/channel/UC_XhgxfirQrft_kAFRZPAOw', '_blank')"
              src="../assets/icons/mobile/you.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="id_strip" class="nav-strip show-nav">
      <div class="bcontainer">
        <div class="d-flex">
          <div class="d-flex ma">
          <img
            src="../assets/icons/pl.png"
            style="height: 14px"
            alt=""
            class="mr-4"
          />
          <div class="striptext">
            Adrress...............................................................
          </div>
        </div>
          <div class="d-flex ma">
            <img
              src="../assets/icons/tim.png"
              alt=""
              style="width: 9px; height: 9px"
              class="mr-4 ma"
            />
            <div class="striptext">24x7 Service</div>
          </div>
          <div class="d-flex ml-15 ma">
            <img
              src="../assets/icons/mailq.png"
              alt=""
              style="width: 9px; height: 9px"
              class="mr-4 ma"
            />
            <div class="striptext">customercare@bleach-kw.com</div>
          </div>
          <div style="flex-grow: 1" class="text-right ma">
            <img
              src="../assets/icons/nav/facebook.png"
              class="mr-6 img-shadow-icon"
              alt=""
            />
            <img
              src="../assets/icons/nav/instagram.png"
              class="mr-6 img-shadow-icon"
              alt=""
            />
            <img
              src="../assets/icons/nav/link.png"
              class="mr-6 img-shadow-icon"
              alt=""
            />
            <img
              src="../assets/icons/nav/twitter.png"
              class="mr-6 img-shadow-icon"
              alt=""
            />
            <img
              src="../assets/icons/nav/youtube.png"
              class="mr-6 img-shadow-icon"
              alt=""
            />
          </div>
        </div>
      </div>
    </div> -->
    <div class="show-nav nav-custom fixed-nav" id="navbar">
      <div class="bcontainer">
        <nav
          style="padding: 0px !important"
          class="navbar navbar-expand-lg navbar-light navbar-fixed-top"
          v-if="selectlan == 'EN'"
        >
          <a class="" href="/">
            <svg
              width="140"
              height="35"
              style="margin-top: -6px"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item dropbtn">
                <a class="nav-link drop-a" style="margin-left: 30px !important"
                  >SERVICES</a
                >
                <div class="nav-dropdown-content m-hide">
                  <div class="d-flex">
                    <div class="nav-drop-div">
                      <div class="nav-drop-head pointer" @click="gotoDc()">
                        DETAILED CLEANING
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/generalcleaning',
                              }).href
                            "
                            >General Cleaning
                          </a>
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/deepcleaning',
                              }).href
                            "
                          >
                            Deep Cleaning
                          </a>
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/storagearea',
                              }).href
                            "
                          >
                            Storage Area</a
                          >
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/windowcleaning',
                              }).href
                            "
                          >
                            Window Cleaning</a
                          >
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/outdoorcleaning',
                              }).href
                            "
                          >
                            Outdoor Cleaning
                          </a>
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/carparking',
                              }).href
                            "
                          >
                            Car Parking Umbrella
                          </a>
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/facadecleaning',
                              }).href
                            "
                          >
                            Facade Cleaning
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="nav-drop-div2">
                      <div class="nav-drop-head pointer" @click="gotosp()">
                        SPECIAL CARE
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/specialcare/upholsterycleaning',
                              }).href
                            "
                          >
                            Upholstery Cleaning
                          </a>
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/specialcare/carpetcleaning',
                              }).href
                            "
                          >
                            Carpet Cleaning
                          </a>
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/specialcare/mattresscleaning',
                              }).href
                            "
                            >Mattress Cleaning
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="nav-drop-div">
                      <div class="nav-drop-head pointer" @click="gotoKit()">
                        KITCHEN CLEANING
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/kitchencleaning/kitchencleaning',
                              }).href
                            "
                          >
                            Kitchen</a
                          >
                        </li>
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/kitchencleaning/kitchenappliances',
                              }).href
                            "
                          >
                            Kitchen Appliances</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="nav-drop-div2">
                      <div class="nav-drop-head pointer" @click="gotoIc()">
                        INFECTION CONTROL
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            :href="
                              $router.resolve({
                                path: '/service/infectioncontrol/sanitisation&disinfection',
                              }).href
                            "
                          >
                            Sanitization & Disinfication</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li class="nav-item dropbtn">
                <a class="nav-link drop-a" @click="gotoSpeciality()"
                  >SPECIALITY</a
                >
                <div class="nav-dropdown-content m-hide"></div>
              </li>
              <li class="nav-item dropbtn">
                <a
                  class="nav-link drop-a"
                  :href="$router.resolve({ path: '/teams' }).href"
                >
                  TEAM</a
                >
                <div class="nav-dropdown-content m-hide"></div>
              </li>
              <li class="nav-item dropbtn">
                <a
                  :href="$router.resolve({ path: '/blog' }).href"
                  class="nav-link drop-a"
                  >BLOG</a
                >
                <div class="nav-dropdown-content m-hide"></div>
              </li>
            </ul>
            <div class="header-right">
              <a class="header-contact"
                ><v-icon color="primary"> mdi-phone-in-talk </v-icon> 1882707</a
              >
              <a
                class="lang"
                @click="setLang('EN')"
                v-if="selectlan == 'ARABIC'"
                >EN</a
              >
              <a
                class="lang"
                @click="setLang('ARABIC')"
                v-if="selectlan == 'EN'"
                >عربي</a
              >
            </div>
          </div>
        </nav>
        <nav
          style="padding: 0px !important"
          class="navbar navbar-expand-lg navbar-light navbar-fixed-top"
          v-if="selectlan == 'ARABIC'"
          dir="rtl"
        >
          <a
            class="navbar-brand"
            href="/"
            style="padding: 0px; margin-right: 0rem"
          >
            <svg
              width="140"
              height="35"
              style="margin-top: -6px"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item dropbtn">
                <a
                  class="nav-link drop-a arabic-font"
                  style="margin-right: 80px !important"
                  >خدمات</a
                >
                <div class="nav-dropdown-content m-hide">
                  <div class="d-flex">
                    <div class="nav-drop-div">
                      <div
                        class="nav-drop-head pointer arabic-font"
                        @click="goto('service/detailedcleaning')"
                      >
                        التنظيف الدقيق
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/generalcleaning',
                              }).href
                            "
                            >التنظيف العام
                          </a>
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/deepcleaning',
                              }).href
                            "
                          >
                            التنظيف العميق
                          </a>
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/storagearea',
                              }).href
                            "
                          >
                            تنظيف منطقة التخزين</a
                          >
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/windowcleaning',
                              }).href
                            "
                          >
                            تنظيف النوافذ</a
                          >
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/outdoorcleaning',
                              }).href
                            "
                          >
                            تنظيف المساحة الخارجية
                          </a>
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/carparking',
                              }).href
                            "
                          >
                            صيانة مظالت مواقف السيارات
                          </a>
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/detailedcleaning/facadecleaning',
                              }).href
                            "
                          >
                            تنظيف الواجهات
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="nav-drop-div2">
                      <div
                        class="nav-drop-head pointer arabic-font"
                        @click="goto('service/specialcare')"
                      >
                        الرعاية الخاصة
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/specialcare/upholsterycleaning',
                              }).href
                            "
                          >
                            تنظيف المفروشات
                          </a>
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/specialcare/carpetcleaning',
                              }).href
                            "
                          >
                            تنظيف السجاد
                          </a>
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/specialcare/mattresscleaning',
                              }).href
                            "
                            >تنظيف المراتب
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="nav-drop-div">
                      <div
                        class="nav-drop-head pointer arabic-font"
                        @click="goto('service/kitchencleaning')"
                      >
                        تنظيف المطبخ
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/kitchencleaning/kitchencleaning',
                              }).href
                            "
                          >
                            تنظيف المطبخ</a
                          >
                        </li>
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/kitchencleaning/kitchenappliances',
                              }).href
                            "
                          >
                            أجهزة المطبخ</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="nav-drop-div2">
                      <div
                        class="nav-drop-head pointer arabic-font"
                        @click="goto('service/infectioncontrol')"
                      >
                        مكافحة انتشار األمراض
                      </div>
                      <ul class="drop-list">
                        <li>
                          <a
                            class="arabic-font"
                            :href="
                              $router.resolve({
                                path: '/service/infectioncontrol/sanitisation&disinfection',
                              }).href
                            "
                          >
                            التعقيم والتطهير</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li class="nav-item dropbtn">
                <a class="nav-link drop-a arabic-font" @click="gotoSpeciality()"
                  >اختصاصاتنا
                </a>
                <div class="nav-dropdown-content m-hide"></div>
              </li>
              <li class="nav-item dropbtn">
                <a
                  class="nav-link drop-a arabic-font"
                  :href="$router.resolve({ path: '/teams' }).href"
                  >فريقنا</a
                >
                <div class="nav-dropdown-content m-hide"></div>
              </li>
              <li class="nav-item dropbtn">
                <a
                  class="nav-link drop-a arabic-font"
                  :href="$router.resolve({ path: '/blog' }).href"
                  >المدونة</a
                >
                <div class="nav-dropdown-content m-hide"></div>
              </li>
            </ul>
            <div
              class="header-right-ar"
              style="float: left; margin-right: auto; margin-left: unset"
            >
              <a class="header-contact">
                1882707 <v-icon color="primary"> mdi-phone-in-talk </v-icon></a
              >
              <a
                class="lang"
                @click="setLang('EN')"
                v-if="selectlan == 'ARABIC'"
                >EN</a
              >
              <a
                class="lang"
                @click="setLang('ARABIC')"
                v-if="selectlan == 'EN'"
                >ARABIC</a
              >
            </div>
          </div>
        </nav>
      </div>
    </div>
    <v-dialog persistent v-model="login_dialog" width="500">
      <div class="pa-6" style="background: #fff">
        <div class="d-flex">
          <div style="flex-grow: 1">
            <svg
              width="140"
              height="35"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="ma">
            <v-icon
              color="primary"
              @click="login_dialog=false,errortext='', (num = true), (reg = false), (log = false)"
            >
              mdi-close-circle-outline
            </v-icon>
          </div>
        </div>
        <div class="h-100">
          <div class="py-10">
            <div class="login-text">Login/Register</div>
            <div v-if="num">
              <div class="d-flex mt-10 login-container">
                <div class="service-content pa-2 lg-phone">+965</div>
                <div style="flex-grow: 1">
                  <input
                    type="number"
                    class="w-100 h-100 login-input pl-2"
                    placeholder="Mobile Number"
                    v-model="phone"
                    v-validate="'required|length:8'"
                    name="mphoe"
                    data-vv-as="Mobile Number"
                  />
                </div>
              </div>
              <span class="red-color">{{ errors.first("mphoe") }}</span>
              <v-alert v-if="otpsentfail" class="mt-5" dense text type="error">
                <span class="red-color"> Failed to sent Otp. Try again </span>
              </v-alert>
              <div class="text-center mt-5">
                <button
                  v-if="!loader"
                  class="btn-otp"
                  :class="phone != '' ? 'active' : ''"
                  @click="otpSubmit()"
                >
                  <span> Get OTP</span>
                </button>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
            <div class="" v-if="log">
              <div class="service-content pt-10 pb-8">
                {{ phone }}
              </div>
              <v-alert v-if="errorFlag" class="mt-5" dense text type="error">
                <span class="red-color"> {{ errortext }}</span>
              </v-alert>
              <v-otp-input
              class="otp-min-height"
                length="6"
                v-model="logOtp"
                plain
                type="number"
              ></v-otp-input>
              <div class="d-flex">
                <div style="flex-grow: 1" class="otp-time">
                  <countdown
                    :key="visible"
                    ref="countdown"
                    :time="2 * 60 * 1000"
                    @end="timeEnd()"
                  >
                    <template slot-scope="props">
                      {{ props.minutes }}:{{ props.seconds }}
                    </template>
                  </countdown>
                </div>
                <div
                  class="otp-time pointer"
                  v-if="resend"
                  @click="resentOtp()"
                >
                  Resend OTP
                </div>
              </div>
              <div class="text-center mt-5">
                <button
                  class="btn-otp active"
                  @click="reqLog()"
                  v-if="logOtp.length == 6"
                >
                  Login
                </button>
                <button class="btn-otp" v-else>Login</button>
              </div>
            </div>
            <div class="" v-if="reg">
              <div class="service-content pt-10 pb-8">
                {{ phone }}
              </div>
              <v-alert v-if="errorFlag" class="mt-5" dense text type="error">
                <span class="red-color"> {{ errortext2 }}</span>
              </v-alert>
              <v-otp-input
              class="otp-min-height"
                length="6"
                v-model="regCustomer.customer_otp"
                plain
                type="number"
                v-validate="'required'"
                name="otp"
                data-vv-as="otp"
              ></v-otp-input>
              <span class="red-color">{{ errors.first("otp") }}</span>

              <div class="d-flex">
                <div style="flex-grow: 1" class="otp-time">
                  <countdown
                    :key="visible"
                    ref="countdown"
                    :time="2 * 60 * 1000"
                    @end="timeEnd()"
                  >
                    <template slot-scope="props">
                      {{ props.minutes }}:{{ props.seconds }}
                    </template>
                  </countdown>
                </div>
                <div
                  class="otp-time pointer"
                  v-if="resend"
                  @click="resentOtp()"
                >
                  Resend OTP
                </div>
              </div>
              <v-text-field
                class="mt-15"
                v-model="regCustomer.name"
                v-validate="'required'"
                name="name"
                data-vv-as="Name"
              >
                <template v-slot:label>
                  <span class="login-label">Name</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("name") }}</span>
              <!-- <v-text-field > 
                 <template  v-slot:label>
                            <span class="login-label">Last Name</span>
                          </template>
              </v-text-field> -->
              <v-text-field
                type="email"
                v-model="regCustomer.email"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
              >
                <template v-slot:label>
                  <span class="login-label">Email</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("email") }}</span>

              <v-select
                v-validate="'required'"
                name="gender"
                data-vv-as="gender"
                v-model="regCustomer.gender"
                :items="genders"
                append-icon="mdi-chevron-down"
              >
                <template v-slot:label>
                  <span class="login-label">Gender</span>
                </template>
              </v-select>
              <span class="red-color">{{ errors.first("gender") }}</span>
              <v-select
                v-validate="'required'"
                name="type"
                data-vv-as="type"
                v-model="regCustomer.customer_type"
                :items="items"
                append-icon="mdi-chevron-down"
              >
                <template v-slot:label>
                  <span class="login-label">Type</span>
                </template>
              </v-select>
              <span class="red-color">{{ errors.first("type") }}</span>
              <v-text-field
                v-if="regCustomer.customer_type == 'CORPORATE'"
                v-model="regCustomer.company"
                v-validate="'required'"
                name="cname"
                data-vv-as="Company name"
              >
                <template v-slot:label>
                  <span class="login-label">Company name</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("cname") }}</span>
              <v-text-field
                v-if="regCustomer.customer_type == 'CORPORATE'"
                v-model="regCustomer.job_title"
                v-validate="'required'"
                name="jobtitile"
                data-vv-as="Job title"
              >
                <template v-slot:label>
                  <span class="login-label">Job title</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("jobtitile") }}</span>
              <div class="text-center mt-5">
                <button class="btn-otp" v-if="!loader" @click="regSubmit">
                  Register
                </button>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import service from "../services/service";
import { mapState } from "vuex";
import $ from "jquery";
export default {
  props:['login_dialog'],
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
     appCart() {
      if (this.$store.state.cartItems == undefined) {
        return 0;
      } else {
        return this.$store.state.cartItems;
      }
    },
    ...mapState({
      // login_dialog: (state) => state.login_dialog,
      log_token: (state) => state.log_token,
    }),
  },
  mounted() {

    this.$store.dispatch("getUserCart");
    var self = this;
    $(document).ready(function () {
      $("#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4").click(function () {
        $(this).toggleClass("open");
        self.menuOpen = !self.menuOpen;
      });
    });
  },
  data() {
    return {
      otpsentfail: false,
      myprofile: false,
      dc: false,
      sp: false,
      kt: false,
      ic: false,
      show: false,
      menuOpen: false,
      selectlan: localStorage.getItem("bleach-lang"),
      loginToken: localStorage.getItem("bleach-tk"),
      loginbar: true,
      loged: false,
      step: 1,
      visible: 1,
      resend: false,
      errortext: "Something went wrong",
      errortext2: "Something went wrong",
      errorFlag: false,
      regCustomer: {
        customer_otp: "",
        name: "",
        gender: "",
        customer_type: "",
        sms_preference: "ENGLISH",
        is_sms: true,
        nationality: "KW",
        email: "",
        company: "",
        job_title: "",
        customer_mobile: "",
      },
      genders: ["MALE", "FEMALE", "OTHER"],
      items: ["INDIVIDUAL", "RETAIL", "CORPORATE"],
      loader: false,
      loaded: false,
      available_slots: [],
      regOtp: "",
      phone: "",
      logOtp: "",
      num: true,
      reg: false,
      log: false,
      dialog: false,
    };
  },

  methods: {

  
    timeEnd() {
      this.resend = true;
    },
    async resentOtp() {
      if (this.phone != "" && !this.loader) {
        this.resend = false;
        this.loader = true;
        let result = await service._post(
          "api/evaluation-booking-customer-otp-generation/",
          { mobile_number: this.phone }
        );
        this.loader = false;
        if (result.status == 200) {
          this.visible = this.visible + 1;
          if (result.data.sms_status != "false") {
            if (result.data.is_new_customer) {
              this.num = false;
              this.reg = true;
            } else {
              this.num = false;
              this.log = true;
            }
          } else {
            this.otpsentfail = true;
          }
        } else {
          this.otpsentfail = true;
        }
      }
    },

    async reqLog() {
      this.loader = true;
      this.errorFlag = false;
      let result = await service._post(
        "api/evaluation-booking-customer-otp-verification/",
        {
          customer_otp: this.logOtp,
          customer_mobile: this.phone,
        }
      );
      this.loader = false;
      if (result.status == 200) {
        if (result.data.otp_verified) {
          this.login_dialog=false;
           this.$store.commit("changeCart", result.data.cart_items_count);
          localStorage.setItem("bleach-tk", result.data.token);
          this.$store.commit("set_login_token", result.data.token);
          this.$store.dispatch("getUserCart");
          // this.loginClose();
          this.loginToken = result.data.token;
        } else {
          this.errortext = result.data.otp_message;
          this.errorFlag = true;
        }
      } else {
        this.errortext = "Something went wrong";

        this.errorFlag = true;
      }
    },
    otpSubmit() {
      this.$validator.validateAll().then((result) => {
        //console.log(result);
        if (!result) {
          return;
        }
        this.getOtp();
      });
    },
    async regReq() {
      this.loader = true;
      this.errorFlag = false;
      this.regCustomer.customer_mobile = this.phone;
      var fd = new FormData();
      fd.append("customer_otp", this.regCustomer.customer_otp);
      fd.append("customer_mobile", this.regCustomer.customer_mobile);
      fd.append("name", this.regCustomer.name);
      fd.append("gender", this.regCustomer.gender);
      fd.append("customer_type", this.regCustomer.customer_type);
      fd.append("sms_preference", this.regCustomer.sms_preference);
      fd.append("is_sms", this.regCustomer.is_sms);
      fd.append("nationality", this.regCustomer.nationality);
      fd.append("email", this.regCustomer.email);
      fd.append("company", this.regCustomer.company);
      fd.append("job_title", this.regCustomer.job_title);

      let result = await service._post(
        "api/evaluation-booking-customer-otp-verification/",
        fd
      );
      this.loader = false;
      if (result.status == 200) {
        if (result.data.otp_verified) {
          this.login_dialog=false;
          // this.loginClose();

          localStorage.setItem("bleach-tk", result.data.token);
          this.$store.commit("set_login_token", result.data.token);
           this.$store.commit("changeCart", result.data.cart_items_count);
          this.$store.dispatch("getUserCart");  
          this.loginToken = result.data.token;
        } else {
          this.errortext2 = result.data.otp_message;
          this.errorFlag = true;
        }
        // //console.log(result)
        //
      } else {
        this.errortext2 = "Something went wrong";

        this.errorFlag = true;
      }
    },
    async getOtp() {
      if (this.phone != "" && !this.loader) {
        this.otpsentfail = false;
        this.loader = true;
        let result = await service._post(
          "api/evaluation-booking-customer-otp-generation/",
          { mobile_number: this.phone }
        );
        this.loader = false;
        if (result.status == 200) {
          if (result.data.sms_status != "false") {
            if (result.data.is_new_customer) {
              this.num = false;
              this.reg = true;
            } else {
              this.num = false;
              this.log = true;
            }
          } else {
            this.otpsentfail = true;
          }
        } else {
          this.otpsentfail = true;
        }
        //console.log(result);
      }
    },
    regSubmit() {
      this.$validator.validateAll().then((result) => {
        //console.log(result);
        if (!result) {
          return;
        }
        this.regReq();
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    logout() {
      localStorage.removeItem("bleach-tk");
      location.reload();
    },
    loginWin() {
      this.$store.commit("set_logindialog", true);
    },
    loginClose() {
      this.$store.commit("set_logindialog", false);
    },
    gotoSpeciality() {
      if (this.$router.currentRoute.path == "/home") {
        this.menuOpen = false;
        this.$nextTick(() =>
          window.document.getElementById("SPECIALITY").scrollIntoView()
        );
      } else {
        this.menuOpen = false;
        localStorage.setItem("bleach-sp", true);
        this.$router.push("/");
      }
    },
    gotoDc() {
      localStorage.setItem("bleach-dc", true);
      this.goto("service/detailedcleaning");
    },
    gotosp() {
      localStorage.setItem("bleach-dc", true);
      this.goto("service/specialcare");
    },
    gotoKit() {
      localStorage.setItem("bleach-dc", true);
      this.goto("service/kitchencleaning");
    },
    gotoIc() {
      localStorage.setItem("bleach-dc", true);
      this.goto("service/infectioncontrol");
    },
    handleScroll() {
      var navbar = document.getElementById("navbar");
      var sticky = navbar.offsetTop;
      if (screen.width > 991) {
        if (window.pageYOffset > sticky) {
          $(".nav-strip").addClass("an-fix");
          $("#navbar").css("top", "0px");
        } else {
          var stripWidth = $(".nav-strip").height();
          var to = stripWidth + 20 + 1;
          $("#navbar").css("top", to + "px");
        }
      }
    },

    goto(loc) {
      //console.log(window.location.href, loc);
      window.location = window.location.href.split("/")[0] + "/" + loc;
    },
    setLang(l) {
      localStorage.setItem("bleach-lang", l);
      location.reload();
    },
  },
};
</script>

<style>
/* Icon 1 */

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 36px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin: 5px auto;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #2b9fad;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 2 */

#nav-icon2 {
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #d3531a;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* Icon 4 */

#nav-icon4 {
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 22.5px;
  left: 8px;
}
</style>
